import { create } from 'zustand';
import { persist } from 'zustand/middleware';
import { _SERVICE as EMAIL_OPT_IN_SERVICE } from '../../declarations/email_opt_in/email_opt_in.did';
import {
    canisterId as emailOptInCanisterId,
    createActor as createEmailOptInActor,
    idlFactory as emailOptInFactory,
} from '../../declarations/email_opt_in/';

import { ActorSubclass, AnonymousIdentity } from '@dfinity/agent';
import { toastError, toast, ToastType } from './toastService';


const isLocal: boolean =
    window.location.origin.includes('localhost') ||
    window.location.origin.includes('127.0.0.1');


export async function getEmailOptInActor(): Promise<ActorSubclass<EMAIL_OPT_IN_SERVICE>> {

    var identity =
        new AnonymousIdentity();
    return createEmailOptInActor(emailOptInCanisterId as string, {
        agentOptions: {
            identity,
            host: isLocal ? 'http://localhost:8080' : 'https://icp-api.io ',
        },
    });
}


export interface EmailOptIn {
    createEmailOptInAddress: (emailAddress: string) => Promise<void>;
}

const createEmailOptIn = (
    set: (state: Partial<EmailOptIn>) => void,
    get: () => EmailOptIn
): EmailOptIn => ({



    createEmailOptInAddress: async (emailAddress: string): Promise<void> => {

        try {
            const emailOptInActor = await getEmailOptInActor();
            await emailOptInActor.createEmailOptInAddress(emailAddress);
            console.log("Email submitted!");
            toast("Email submitted!", ToastType.Success);


        } catch (error) {
            toastError(error);
            console.error(error);
            return undefined;
        }
    }
});

export const useEmailOptIn = create<EmailOptIn>()(
    persist(
        (set, get) => createEmailOptIn(set, get) as any,
        {
            name: 'EmailOptIn',
            getStorage: () => sessionStorage,
        }
    )
);
