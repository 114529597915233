import React, { useState } from 'react';
import './_home.scss';
import { useEmailOptIn } from '../../service/email_opt_in';
import GlitchText from '../../components/GlitchText/GlitchText';

const Home: React.FC = () => {
    const [email, setEmail] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const emailOptIn = useEmailOptIn();

    const handleSignUp = async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        setIsLoading(true);
        if (email) {
            try {
                await emailOptIn.createEmailOptInAddress(email);
                setEmail('');
            } catch (error) {
                console.error('Failed to opt-in email address:', error);
            }
        } else {
            console.log('Email field is empty.');
        }
        setIsLoading(false);
    };

    return (
        <div className="container">
            <GlitchText text="Chainkeys is the most secure password manager available." />
            <div className='signup-container'>
                <p className="signup-prompt">Sign up here to join the waiting list</p>
                <form className="email-signup" onSubmit={handleSignUp}>
                    <input
                        type="email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        placeholder="email"
                        disabled={isLoading}
                    />
                    <button type="submit" disabled={isLoading}>
                        {isLoading ? <div className="spinner"></div> : 'Join'}
                    </button>
                </form>
            </div>
            <p className="disclaimer">
                Your email will be used in total twice. Once when we launch. Then two weeks after we launch in case you missed the launch.
                Your email address will never be seen or sold or otherwise used by anybody except Aikin for the aforementioned two emails.
            </p>

        </div>
    );
};

export default Home;
