import React, { useEffect, useState } from 'react';
import './_GlitchText.scss';

interface GlitchTextProps {
    text: string;
}

const GlitchText: React.FC<GlitchTextProps> = ({ text }) => {
    const [displayedText, setDisplayedText] = useState<string>(text);

    useEffect(() => {
        const special: string[] = ['~', '@', '!', '#', '$', '%', '^', '&', '*'];
        const exception: string[] = [' ', '\n', '.', ','];
        const random = (min: number, max: number): number => Math.floor(Math.random() * (max - min + 1) + min);

        const numArray: number[] = Array.from(text).map(() => random(5, 40));
        let completeCount = 0;

        const timer = setInterval(() => {
            let newText = '';
            numArray.forEach((num, i) => {
                if (exception.includes(text[i]) || numArray[i] === 0) {
                    newText += text[i];
                    completeCount += 1;
                } else {
                    newText += special[numArray[i] % special.length];
                    numArray[i]--;
                }
            });

            setDisplayedText(newText);

            if (completeCount === numArray.length) {
                clearInterval(timer);
            }
        }, 100);

        return () => clearInterval(timer);
    }, [text]);

    return <p className="glitch-text">{displayedText}</p>;
};

export default GlitchText;
