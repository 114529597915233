import React from 'react';
import ReactDOM from 'react-dom';
import Home from './screens/home/home'
import './index.scss'; // Global styles
import { Toaster } from 'react-hot-toast';

ReactDOM.render(
  <React.StrictMode>
    <Toaster position="bottom-center" toastOptions={{
      style: {
        backgroundColor: '#444',
        color: '#ffffff',
        filter: 'drop-shadow(0px 0px 100px white)'
      },
    }}
    />
    <Home /> {/* Render the Home component */}
  </React.StrictMode>,
  document.getElementById('root')
);
